<template>
    <section>
        <b-card>
            <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Newsletter </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Crea template da Editor</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

        <div class="mt-2">
            In allestimento
        </div>

        </b-card>

    </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  VBTooltip,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
  },

  directives: {
      'b-tooltip': VBTooltip,
  }
};
</script>